<template>
	<div class="auth-form">
		<div class="mb-40">
			<h1 class="h1 text-center">Войти</h1>
			<!--<span class="p3">или <NuxtLink to="/login/registration" class="link link&#45;&#45;underline">зарегистрироваться</NuxtLink></span>-->
		</div>

		<form
			class="auth-form__form form mb-16"
			@keyup.enter="onSubmit()">
			<div
				class="form__group"
				:class="{ 'form__group--error': v$.login.$invalid && v$.login.$dirty }">
				<label
					for=""
					class="form__label"
					>Логин</label
				>

				<input
					v-model="loginData.login"
					type="text"
					class="auth-form__field form__field form__label-light form__field--md form__field--no-bold"
					placeholder="Введите логин" />
			</div>

			<div
				class="form__group auth-form__group mb-40"
				:class="{ 'form__group--error': v$.password.$invalid && v$.password.$dirty }">
				<label
					for="password"
					class="form__label"
					>Пароль</label
				>

				<div class="form__field-icon">
					<input
						id="password"
						v-model="loginData.password"
						:type="showPassword ? 'text' : 'password'"
						class="auth-form__field form__field form__label-light form__field--md form__field--no-bold"
						placeholder="Введите пароль" />

					<nuxt-icon
						class="nuxt-icon--top icon"
						name="eye"
						@click="showPassword = !showPassword"></nuxt-icon>
				</div>

				<div
					v-if="errorText"
					class="auth-form__wrong-data">
					{{ errorText }}
				</div>
			</div>

			<UiButton
				v-if="!fetchAuth"
				type="button"
				:name="'Войти'"
				:button-full-width="true"
				:size="'sm'"
				:disabled="v$.$invalid"
				@click="onSubmit()" />

			<Loader
				v-else
				class="m-auto"></Loader>
		</form>

		<!--<NuxtLink to="/login/forgot-password" class="link link&#45;&#45;underline p3">Забыли пароль?</NuxtLink>-->
	</div>
</template>

<script setup>
	import { computed, reactive } from "vue";
	import { required } from "@vuelidate/validators";
	import { useVuelidate } from "@vuelidate/core";
	import { useState } from "#app";
	import { useUser } from "~/composables/useUser";
	import { useSerialize } from "~/composables/useSerialize";

	const { signIn } = useAuth();

	const loginData = reactive({
		login: "",
		password: "",
	});

	const rules = computed(() => ({
		login: { required, $autoDirty: true },
		password: { required, $autoDirty: true },
	}));

	const v$ = useVuelidate(rules, loginData);

	const showPassword = useState("showPassword", () => false);

	const errorText = useState("authErrorText", () => "");
	errorText.value = "";
	const fetchAuth = useState("fetchAuth", () => false);

	const onSubmit = async () => {
		if (!v$.value.$invalid) {
			fetchAuth.value = true;
			const redirectedFrom = new URL(useRoute().query.callbackUrl);
			const redirectUrlTo = redirectedFrom.searchParams.get("redirect_uri");
			errorText.value = "";

			const res = await signIn("credentials", {
				callbackUrl: "/",
				redirect: false,
				username: loginData.login,
				password: loginData.password,
			});

			if (res.error) {
				errorText.value = res.error;
			} else if (redirectUrlTo) {
				const newRedirectUrl = new URL(redirectUrlTo);
				const queryParamsSize = newRedirectUrl.searchParams.size;
				const redirectToUrl = `${newRedirectUrl}${queryParamsSize ? "&" : "?"}token=${useUser().accessToken}&${useSerialize(useRoute().query)}`;

				window.location.replace(redirectToUrl);
			} else {
				await useRouter().push("/");
			}
			fetchAuth.value = false;
		}
	};
</script>

<style lang="scss">
	@import "auth-form";
</style>
