<template>
	<AuthForm />
</template>

<script setup>
	import { useHead, useState } from "#app";
	import AuthForm from "~/components/page/login/index/auth-form/auth-form.vue";

	definePageMeta({
		layout: "auth",
		middleware: ["callback-url"],
		auth: {
			unauthenticatedOnly: true,
			navigateAuthenticatedTo: "/",
		},
	});

	const pageTitle = useState("pageTitle", () => "Авторизация");
	useHead({
		title: pageTitle.value,
	});
</script>
